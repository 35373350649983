import { CrudOptions } from '@hutsix/ngxh6';
import cruds_conf from '../_generated/ext_config/cruds.json';

/** Override generated CRUD values here */
const cruds_conf_overrides: Override = {
  occupants: {
    archivable: true,
    sortable: true,
    searchable: true,
    filterable: true,
    tableSpec: {
      columns: [
        { header: 'Name', value: 'name' },
        { header: 'Weight', value: 'weight' },
        { header: 'Crew?', value: 'crew', type: 'boolean' },
        { header: 'Passenger?', value: 'passenger', type: 'boolean' },
        { header: 'Litter?', value: 'litter', type: 'boolean' },
        { header: 'Troop?', value: 'troop', type: 'boolean' },
        { header: 'Expendable?', value: 'expendable', type: 'boolean' },
        { header: 'Nominal?', value: 'nominal', type: 'boolean' },
        { header: 'Empty?', value: 'empty', type: 'boolean' },
        { header: 'Custom Input?', value: 'customInput', type: 'boolean' },
      ],
    },
  },
  pallets: {
    archivable: true,
    sortable: true,
    searchable: true,
    filterable: true,
    tableSpec: {
      columns: [
        { header: 'Name', value: 'name' },
        { header: 'Weight', value: 'weight' },
        { header: 'Length', value: 'length' },
        { header: 'Width', value: 'width' },
        { header: 'Empty?', value: 'empty', type: 'boolean' },
        { header: 'Custom Input?', value: 'customInput', type: 'boolean' },
      ],
    },
  },
  fuel_types: {
    archivable: true,
    sortable: true,
    searchable: true,
    filterable: true,
    tableSpec: {
      columns: [
        { header: 'Name', value: 'name' },
        { header: 'Density', value: 'density' },
      ],
    },
  },
};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  // test: new CrudOptions({
  //   objectLabel: 'Test',
  //   apiEndpoint: '/api/v1/examples',
  // }),
};

/**
 *
 * You shouldn't need to change anything below
 *
 */
export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

export function getCrud(slug: CrudKeys): CrudOptions {
  return getCrudsConfig()[slug];
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}

type Override = { [Property in keyof Partial<typeof cruds_conf>]: Partial<CrudOptions> };
